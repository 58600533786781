import {
  Button,
  IconTypes,
  Icons,
  ShapeIconOptions,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { getLastFileProcessingUseCase } from "../../PayrollLoan/useCases/getLastFileProcessingUseCase";
import { StatusDetailsProps } from "../components/StatusDetails";
import { StatusHeaderProps } from "../components/StatusHeader";
import { RouterOutput } from "server/src/routers/trpc";

enum UploadSheetInfoPageTypes {
  SheetProcessingError = "SHEET_PROCESSING_ERROR",
  UpsertEmployeeError = "UPSERT_EMPLOYEE_ERROR",
  Processing = "PROCESSING",
  Success = "SUCCESS",
}

type ErrorType = {
  message: string;
  line: number;
};

type LocationState = {
  errors: ErrorType[];
  successLines: number;
  errorLines: number;
  companyIds: string[];
};

type LastFileProcessing =
  RouterOutput["fileProcessing"]["getLastFileProcessing"];

const defaultLastFileProcessing: NonNullable<LastFileProcessing> = {
  companyId: "",
  companyIds: [],
  errors: [],
  lines: { failed: 0, success: 0, total: 0 },
  path: "",
  status: "",
  updatedAt: "",
};

export function useUploadSheetInfoViewModel() {
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const getStatusHeaderProps = (
    uploadSheetInfoPageType: UploadSheetInfoPageTypes,
    successfullImports: number,
    failureImports: number,
    numberOfCompanies: number
  ): StatusHeaderProps | undefined => {
    const descriptionList: StatusHeaderProps["description"] = [
      {
        icon: "IconUser",
        text:
          successfullImports === 1
            ? `${successfullImports} pessoa foi importada com sucesso.`
            : `${successfullImports} pessoas foram importadas com sucesso.`,
      },
    ];
    if (numberOfCompanies > 1) {
      descriptionList.push({
        icon: "IconBuildingCommunity",
        text: `${numberOfCompanies} empresas foram atualizadas com sucesso.`,
      });
    }
    if (failureImports > 0) {
      descriptionList.push({
        icon: "IconUserX",
        text:
          failureImports === 1
            ? `${failureImports} pessoa não pôde ser importada.`
            : `${failureImports} pessoas não puderam ser importadas.`,
      });
    }

    const mapper: { [key in UploadSheetInfoPageTypes]: StatusHeaderProps } = {
      [UploadSheetInfoPageTypes.Success]: {
        description: [
          ...descriptionList,
          {
            text: "Confira na lista de pessoas os cadastros importados.",
          },
        ],
        shapeIconProps: {
          name: "IconCheck" as IconTypes,
          size: 48,
          variant: "success" as ShapeIconOptions,
          color: theme.colors.feedback.success[40],
        },
        title: "Sucesso na importação!",
      },
      [UploadSheetInfoPageTypes.UpsertEmployeeError]: {
        description: [...descriptionList],
        shapeIconProps: {
          name: "IconAlertTriangle" as IconTypes,
          size: 48,
          variant: "error" as ShapeIconOptions,
          color: theme.colors.feedback.error[50],
        },
        title: "Processamento finalizado",
      },
      [UploadSheetInfoPageTypes.SheetProcessingError]: {
        description: [
          {
            text: `Houve uma falha no preenchimento da planilha impedindo que ela seja analisada. Será necessária uma nova importação do arquivo corrigido.`,
          },
        ],
        shapeIconProps: {
          name: "IconAlertTriangle" as IconTypes,
          size: 48,
          variant: "error" as ShapeIconOptions,
          color: theme.colors.feedback.error[50],
        },
        title: "Não foi possível concluir o seu processamento",
      },
      [UploadSheetInfoPageTypes.Processing]: {
        description: [
          {
            text: "Assim que o processamento do seu arquivo for finalizado, notificaremos aqui na plataforma o resultado da importação :)",
          },
        ],
        shapeIconProps: {
          name: "IconRefresh" as IconTypes,
          size: 48,
          variant: "default" as ShapeIconOptions,
          color: theme.colors.primary,
        },
        title: "Em processamento",
      },
    };
    return mapper[uploadSheetInfoPageType];
  };

  const getStatusDetailsProps = (
    uploadSheetInfoPageType: UploadSheetInfoPageTypes,
    successfullImports: number,
    failureImports: number,
    totalImports: number,
    numberOfCompanies: number
  ): StatusDetailsProps | undefined => {
    const completedImports = successfullImports + failureImports;
    const progress = totalImports
      ? Math.floor((completedImports * 100) / totalImports)
      : 0;

    const numberOfImportsText =
      totalImports > 1
        ? `${successfullImports} de ${totalImports} pessoas importadas`
        : `${successfullImports} de ${totalImports} pessoa importada`;

    const optionalNumberOfCompaniesText =
      numberOfCompanies > 1
        ? ` em ${numberOfCompanies} empresas diferentes`
        : "";

    return {
      [UploadSheetInfoPageTypes.Success]: {
        description: `${numberOfImportsText}${optionalNumberOfCompaniesText}.`,
        progress: 100,
        title: "Processamento finalizado",
      },
      [UploadSheetInfoPageTypes.UpsertEmployeeError]: {
        description:
          "Confira abaixo os erros. Você deverá refazer a importação dessas pessoas.",
        title: "Alguns cadastros não puderam ser importados.",
      },
      [UploadSheetInfoPageTypes.Processing]: {
        description: `${numberOfImportsText}.`,
        progress,
        title: "Progresso",
      },
    }[uploadSheetInfoPageType];
  };

  const RedirectPayrollLoanPageButton = ({
    buttonType,
  }: {
    buttonType: "primary" | "secondary";
  }) => {
    const iconColor = {
      primary: theme.colors.neutral[100],
      secondary: theme.colors.secondary[40],
    }[buttonType];

    return (
      <Button
        size="large"
        variant={buttonType}
        variantType="default"
        onClick={() => navigate("/payroll-loan", { replace: true })}
      >
        <Icons
          name="IconArrowNarrowLeft"
          color={iconColor}
          fill="transparent"
        />
        Voltar para a lista de pessoas
      </Button>
    );
  };

  const RedirectUploadSheetPageButton = () => {
    return (
      <Button
        size="large"
        variant="primary"
        variantType="default"
        onClick={() =>
          navigate("/payroll-loan/upload-sheet", { replace: true })
        }
      >
        Nova importação
        <Icons name="Add" color={theme.colors.neutral[100]} />
      </Button>
    );
  };

  const getSecondaryButton = (
    uploadSheetInfoPageType: UploadSheetInfoPageTypes
  ): React.FC | undefined => {
    return {
      [UploadSheetInfoPageTypes.SheetProcessingError]: () => (
        <RedirectPayrollLoanPageButton buttonType={"secondary"} />
      ),
      [UploadSheetInfoPageTypes.UpsertEmployeeError]: () => (
        <RedirectPayrollLoanPageButton buttonType={"secondary"} />
      ),
    }[uploadSheetInfoPageType];
  };

  const getPrimaryButton = (
    uploadSheetInfoPageType: UploadSheetInfoPageTypes
  ): React.FC | undefined => {
    return {
      [UploadSheetInfoPageTypes.SheetProcessingError]: () => (
        <RedirectUploadSheetPageButton />
      ),
      [UploadSheetInfoPageTypes.UpsertEmployeeError]: () => (
        <RedirectUploadSheetPageButton />
      ),
      [UploadSheetInfoPageTypes.Processing]: () => (
        <RedirectPayrollLoanPageButton buttonType={"primary"} />
      ),
      [UploadSheetInfoPageTypes.Success]: () => (
        <RedirectPayrollLoanPageButton buttonType={"primary"} />
      ),
    }[uploadSheetInfoPageType];
  };

  const {
    data: lastFileProcessing = defaultLastFileProcessing,
    refetch: refetchLastFileProcessing,
    isLoading,
  } = getLastFileProcessingUseCase(selectedCompany.id);

  const { lines } = lastFileProcessing;

  const progress = lines.total
    ? ((lines.success + lines.failed) * 100) / lines.total
    : 0;

  const getUploadSheetInfoPageType = (
    hasErrors: boolean,
    status: string
  ): UploadSheetInfoPageTypes | undefined => {
    if (status === "FINISHED" && !hasErrors) {
      return UploadSheetInfoPageTypes.Success;
    }
    if (status === "FAILED") {
      return UploadSheetInfoPageTypes.SheetProcessingError;
    }
    if (status === "FINISHED" && hasErrors) {
      return UploadSheetInfoPageTypes.UpsertEmployeeError;
    }
    if (status === "PROCESSING") {
      return UploadSheetInfoPageTypes.Processing;
    }
  };

  const uploadSheetInfoPageType = getUploadSheetInfoPageType(
    !!lastFileProcessing.errors?.length,
    lastFileProcessing.status
  );

  useEffect(() => {
    const intervalId = setInterval(refetchLastFileProcessing, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [lastFileProcessing, refetchLastFileProcessing]);

  if (location.state) {
    const { errors, successLines, errorLines, companyIds } =
      location.state as LocationState;
    const shouldRenderErrorPageType = !!errors;
    if (shouldRenderErrorPageType) {
      const totalImports = successLines + errorLines;
      const uploadSheetInfoPageType =
        UploadSheetInfoPageTypes.UpsertEmployeeError;
      return {
        status: lastFileProcessing.status,
        errors,
        isLoading: false,
        PrimaryButton: getPrimaryButton(uploadSheetInfoPageType),
        SecondaryButton: getSecondaryButton(uploadSheetInfoPageType),
        statusHeaderProps: getStatusHeaderProps(
          uploadSheetInfoPageType,
          successLines,
          errorLines,
          companyIds?.length ?? 0
        ),
        statusDetailsProps:
          uploadSheetInfoPageType &&
          getStatusDetailsProps(
            uploadSheetInfoPageType,
            successLines,
            errorLines,
            totalImports,
            companyIds?.length ?? 0
          ),
      };
    }
  }

  return {
    isLoading,
    ...(uploadSheetInfoPageType
      ? {
          status: lastFileProcessing.status,
          errors: lastFileProcessing.errors,
          PrimaryButton: getPrimaryButton(uploadSheetInfoPageType),
          progress,
          SecondaryButton: getSecondaryButton(uploadSheetInfoPageType),
          statusHeaderProps: getStatusHeaderProps(
            uploadSheetInfoPageType,
            lines.success,
            lines.failed,
            lastFileProcessing?.companyIds?.length ?? 0
          ),
          statusDetailsProps: getStatusDetailsProps(
            uploadSheetInfoPageType,
            lines.success,
            lines.failed,
            lines.total,
            lastFileProcessing?.companyIds?.length ?? 0
          ),
        }
      : {}),
  };
}
