import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import { NotificationCardProps } from "@flash-tecnologia/hros-web-ui-v2/dist/components/NotificationCard/Root";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { dayjs } from "@utils/dayjs";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../../utils/masks/formatDate";
import { NotificationBannerProps } from "../components/NotificationBanners";
import { getExpirationSummaryUseCase } from "../useCases/getExpirationSummaryUseCase";
import { getLastFileProcessingUseCase } from "../useCases/getLastFileProcessingUseCase";

const EXPIRATION_ALERT_THRESHOLD_IN_DAYS = 7;

export function useNotificationBannersViewModel() {
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();

  const handleGetExpirationSummary = getExpirationSummaryUseCase(
    selectedCompany.id
  );

  const handleGetLastFileProcessing = getLastFileProcessingUseCase(
    selectedCompany.id
  );

  const lastFileProcessing = handleGetLastFileProcessing.data ?? {
    errors: [],
    lines: { success: 0, failed: 0, total: 0 },
    status: "",
    updatedAt: undefined,
    companyIds: [],
  };

  const {
    errors,
    lines,
    status,
    updatedAt: lastImportFinishedAt,
    companyIds,
  } = lastFileProcessing;

  const lastImportFinishedAtFormatted =
    lastImportFinishedAt && formatDateTime(new Date(lastImportFinishedAt));

  const getFileProcessingNotificationCardType = (
    status: string,
    hasErrors: boolean
  ): NotificationCardProps["type"] | undefined => {
    if (status === "FINISHED" && !hasErrors) {
      return "success";
    }
    if (status === "FAILED" || (status === "FINISHED" && hasErrors)) {
      return "error";
    }
    if (status === "PROCESSING") {
      return "info";
    }
  };

  const getFileProcessingNotificationCardTitle = (
    type: NotificationCardProps["type"],
    status: string
  ) => {
    if (type === "success")
      return `Importação finalizada com sucesso em ${lastImportFinishedAtFormatted}`;

    if (type === "info") return "Importação de pessoas em andamento";

    if (type === "error") {
      if (status === "FINISHED")
        return "Processamento finalizado. Alguns cadastros não puderam ser importados";
      else return "Não foi possível concluir o seu processamento";
    }
  };

  const getFileProcessingNotificationCardSubtitle = (
    type: NotificationCardProps["type"],
    status: string
  ) => {
    const numberOfCompaniesText =
      companyIds.length > 1
        ? `, e ${companyIds.length} empresas foram atualizadas`
        : "";

    const successText =
      lines.success === 1
        ? `${lines.success} pessoa foi importada com sucesso.`
        : `${lines.success} pessoas foram importadas com sucesso${numberOfCompaniesText}.`;

    const failText =
      lines.failed === 1
        ? `${lines.failed} pessoa não pôde ser importada. Você deverá refazer a importação dessa pessoa.`
        : `${lines.failed} pessoas não puderam ser importadas. Você deverá refazer a importação dessas pessoas.`;

    if (type === "success") return successText;

    if (type === "info")
      return "Enquanto isso, você pode continuar navegando normalmente. Assim que sua importação estiver concluída, notificaremos aqui na plataforma :)";

    if (type === "error") {
      if (status === "FINISHED") return `${successText}\n${failText}`;
      else
        return "Houve uma falha no preenchimento da planilha impedindo que ela seja analisada. Será necessária uma nova importação do arquivo corrigido.";
    }
  };

  const getFileProcessingNotificationCardIconName = (
    type: NotificationCardProps["type"]
  ): IconTypes => {
    return {
      error: "IconCircleX",
      info: "IconAlertCircle",
      success: "IconCheck",
    }[type] as IconTypes;
  };

  const handleRedirectToUploadSheetDetailsPage = () => {
    navigate("/payroll-loan/upload-sheet/info", {
      state: {
        errors,
        successLines: lines.success,
        errorLines: lines.failed,
      },
    });
  };

  const getRedirectLink = (
    notificationCardType: NotificationCardProps["type"]
  ) => {
    return {
      error: {
        onClick: handleRedirectToUploadSheetDetailsPage,
        text: "Confira os erros",
        variant: "error",
      },
      info: {
        onClick: handleRedirectToUploadSheetDetailsPage,
        text: "Acompanhar progresso",
        variant: "default",
      },
    }[notificationCardType];
  };

  const fileProcessingNotificationCardType =
    getFileProcessingNotificationCardType(status, !!errors.length);

  const fileProcessingNotificationCard =
    fileProcessingNotificationCardType &&
    ({
      redirectLink: getRedirectLink(fileProcessingNotificationCardType),
      iconName: getFileProcessingNotificationCardIconName(
        fileProcessingNotificationCardType
      ),
      subtitle: getFileProcessingNotificationCardSubtitle(
        fileProcessingNotificationCardType,
        status
      ),
      title: getFileProcessingNotificationCardTitle(
        fileProcessingNotificationCardType,
        status
      ),
      type: fileProcessingNotificationCardType,
    } as NotificationBannerProps);

  const { expiredEmployeesCount, nextExpirationDate } =
    handleGetExpirationSummary.data ?? {};

  const getOutdatedDatabaseNotificationCard = () => {
    const iconName = "IconAlertCircle" as IconTypes;
    const type = "error" as NotificationCardProps["type"];

    if (expiredEmployeesCount) {
      return {
        iconName,
        subtitle: `O acesso ao consignado expirou para ${expiredEmployeesCount} pessoa${
          expiredEmployeesCount > 1 ? "s" : ""
        } de sua base`,
        title: "Atualize sua base!",
        type,
      } as NotificationBannerProps;
    }

    if (nextExpirationDate) {
      const today = dayjs();
      const differenceInDays = dayjs(nextExpirationDate).diff(today, "day");
      if (differenceInDays <= EXPIRATION_ALERT_THRESHOLD_IN_DAYS) {
        return {
          iconName,
          subtitle: `O acesso ao consignado irá expirar para alguma(s) pessoa(s) de sua base em ${differenceInDays} dia${
            differenceInDays > 1 ? "s" : ""
          }.`,
          title: "Atualize sua base!",
          type,
        } as NotificationBannerProps;
      }
    }

    if (!lastImportFinishedAt) {
      return {
        iconName,
        subtitle:
          "Você ainda não habilitou nenhum colaborador com consignado, faça o upload da planilha para habitá-los.",
        title: "Atenção!",
        type,
      } as NotificationBannerProps;
    }
  };

  const outdatedDatabaseNotificationCard =
    getOutdatedDatabaseNotificationCard();

  return {
    isLoading:
      handleGetExpirationSummary.isLoading ||
      handleGetLastFileProcessing.isLoading,
    fileProcessingNotificationCard,
    outdatedDatabaseNotificationCard,
  };
}
