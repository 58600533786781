import {
  Icons,
  IconsProps,
  ShapeIcon,
  ShapeIconProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Spacer from "../../../../components/Spacer";
import Typography from "../../../../components/Typography";
import FlexBox from "@atoms/FlexBox";

type Description = { icon?: IconsProps["name"]; text: string };

export interface StatusHeaderProps {
  description: Description[];
  shapeIconProps: ShapeIconProps;
  title: string;
}

const StatusHeader = ({
  description,
  shapeIconProps,
  title,
}: StatusHeaderProps) => {
  const theme = useTheme();
  return (
    <>
      <ShapeIcon
        name={shapeIconProps.name}
        size={shapeIconProps.size}
        variant={shapeIconProps.variant}
        color={shapeIconProps.color}
      />
      <Spacer y="xs4" />
      <Typography.Headline8>{title}</Typography.Headline8>
      <Spacer y="xs5" />
      {description.map((item, i) => {
        if (!item) return;
        return (
          <FlexBox flexDirection="row" gap="xs4" key={i}>
            {item.icon && (
              <Icons
                name={item.icon}
                color={theme.colors.neutral[50]}
                size={16}
              />
            )}
            <Typography.Body4 color={theme.colors.neutral[50]}>
              {item.text}
            </Typography.Body4>
          </FlexBox>
        );
      })}
      <Spacer y="s" />
    </>
  );
};

export default StatusHeader;
